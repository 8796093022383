import { serviceOpsFollowupConstants } from '../../_constants';


const initialState = {
    loading: false,
    shipmentComment: {
        shipmentId: null,
        comment: '',
        trackComplete: false,
    },
    selectedShipment: {
        loading: false,
        shipmentComments: [],
        shipmentStatuses: []
    },
    data: {
        rows: []
    },
};

const integerRegExp = /^$|^\d*[0-9]\d*$/

export function serviceOpsFollowup(state = initialState, action) {
    switch (action.type) {
        case serviceOpsFollowupConstants.LOADING:
            return {
                ...state,
                loading: true
            }

        case serviceOpsFollowupConstants.COMMENTS_LOADING:
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    loading: true,
                }
            }

        case serviceOpsFollowupConstants.ERROR:
            return {
                ...state,
                loading: false
            };

        case serviceOpsFollowupConstants.UPDATE_COMMENT_VALUE:
            return {
                ...state,
                shipmentComment: {
                    ...state.shipmentComment,
                    [action.data.name]: action.data.value
                }
            }

        case serviceOpsFollowupConstants.UPDATE_SELECTED_SHIPMENT_COMMENTS_AND_STATUSES:
            return {
                ...state,
                loading: false,
                selectedShipment: {
                    ...state.selectedShipment,
                    loading: false,
                    shipmentComments: action.data.shipmentComments,
                    shipmentStatuses: action.data.shipmentStatuses
                }
            };

        case serviceOpsFollowupConstants.UPDATE_FOLLOWUP_TABLE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    ...state.data,
                    rows: action.data
                }
            };

        case serviceOpsFollowupConstants.UPDATE_SHIPMENT_COMMENTS:
            return {
                ...state,
                data: {
                    ...state.data,
                    rows: !action.data.trackComplete ? state.data.rows.map(row =>
                        row.id === action.data.shipmentId
                            ? {
                                ...row,
                                comment: action.data.comment,
                                commentDate: action.data.commentDate
                            }
                            : row
                    ) : state.data.rows.filter(x => x.id !== action.data.shipmentId)
                }
            };

        default:
            return state
    }
}