import { adminService, shipmentService, serviceOpsService } from '../../_services';
import { serviceOpsConstants } from '../../_constants';

export const serviceOpsActions = {
    setTabValue,
    toggleFullWidthTable
}

function setTabValue(newValue){ 
    return {type: serviceOpsConstants.SET_TAB_VALUE, newValue}
}

function toggleFullWidthTable(isFullWidth){ 
    return {type: serviceOpsConstants.TOGGLE_FULL_WIDTH_TABLE, isFullWidth}
}