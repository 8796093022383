import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SuiButton from '_softUI/components/SuiButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import { serviceOpsActions } from '../../../_actions';

const CustomToolbar = ({ columnVisibility, columnVisibilityOptions, columnVisibilityChange, checkedRowActions, selectionModel, moveSelected, tableSwitchButton }) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.serviceOps)

  useEffect(() => {
    const storedValue = localStorage.getItem('fullWidthTable');
    if (storedValue !== null) {
      const value = storedValue === 'true';
      if (value !== state.fullWidthTable){
          dispatch(serviceOpsActions.toggleFullWidthTable(value)); 
      }
    }
  }, [dispatch]);

  const handleToggleFullWidth = (event) => {
    const newValue = event.target.checked
    dispatch(serviceOpsActions.toggleFullWidthTable(newValue));
    localStorage.setItem('fullWidthTable', newValue); 
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
        <Box display='flex' alignItems='center'>
          {tableSwitchButton}
          <FormGroup row >
            {columnVisibilityOptions.map((option) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={option.columns.every((column) => columnVisibility[column])}
                    onChange={() => columnVisibilityChange(option.columns)}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box display='flex' alignItems='center'>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  // value="Full width"
                  control={<Switch color="primary" checked={state.fullWidthTable} onChange={handleToggleFullWidth}/>}
                  label="Full width"
                  labelPlacement="bottom"
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Box display='flex' alignItems='center'>
            <GridToolbarDensitySelector />
            <GridToolbarExport />
          </Box>
          <Box >
            {/* Quick Filter */}
            <GridToolbarQuickFilter />
          </Box>
        </Box>
      </Box>

      {selectionModel.length > 0 && (
        <Box sx={{
          display: 'flex',
          justifyContent: checkedRowActions[0] === "Mark completed" || checkedRowActions[0] === "Add prices to shipments" ? 'flex-end' : 'flex-start'
        }}>
          {checkedRowActions.map((action) => (
            <SuiButton onClick={() => { moveSelected(selectionModel, action) }} variant="outlined" size="small" color="primary" sx={{ marginLeft: '8px' }}>
              <KeyboardDoubleArrowRightIcon size="large" />{action}
            </SuiButton>
          ))}
        </Box>
      )}
    </>
  );
};

export default CustomToolbar;