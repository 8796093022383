import { AiTwotonePropertySafety } from 'react-icons/ai';
import { instantPricingConstants } from '../_constants';
import { setUserLocalStorage, validate, validateState } from '../_helpers'
import { typeOptionsRoad } from '../_helpers';
import { goodsRowModel, adrRowModel } from './models';

const initialState = {
  originCountry: { value: '', label: '' },
  originZip: { value: '', label: '' },
  originZipOptions: [],
  originZipSelected: false,
  originZipHint: false,
  originZipRegex: '',
  originZipExample: '',
  destinationCountry: { value: '', label: '' },
  destinationZip: { value: '', label: '' },
  destinationZipOptions: [],
  destinationZipSelected: false,
  destinationZipHint: false,
  destinationZipRegex: '',
  destinationZipExample: '',
  selections: false,
  countries: [],
  loading: false,
  calculating: false,
  weight: '',
  weightError: false,
  volume: '',
  volumeError: false,
  ldm: '',
  ldmError: false,
  billingWeightTotal: 0,
  goods: [goodsRowModel],
  totalWeight: 0,
  totalLdm: 0,
  totalVolume: 0,
  billingWeight: 0,
  noResults: false,
  results: [],
  valid: false,
  tailLift: false,
  insurance: false,
  adr: false,
  goodsValue: '',
  goodsValueError: false,
  adrClassError: false,
  adrClass: [],
  showAdditionalServices: false,
  callOnPickup: false,
  callOnDelivery: false,
  importDeclaration: false,
  exportDeclaration: false


};

export function instantPricing(state = initialState, action) {

  const integerRegExp = /^$|^\d*[0-9]\d*$/

  const doubleRegExp = /^$|^([0-9]*[\.|\,])?[0-9]+/

  let newState = {}

  switch (action.type) {
    case instantPricingConstants.LOADING:
      return {
        ...state,
        loading: true,
        valid: validateState(state)
      };

    case instantPricingConstants.CALCULATE:
      return {
        ...state,
        calculating: true,
      };



    case instantPricingConstants.UPDATE_COUNTRIES:

      newState = {
        ...state,
        loading: false,
        results: [],
        countries: action.data,
      }

      return {
        ...newState,
        valid: validateState(newState)

      };

    case instantPricingConstants.SET_INITIAL_STATE:

      return {
        ...initialState

      };

    case instantPricingConstants.UPDATE_VALUE:
      const name = action.data.name
      let insValid = true;
      //let adrValid = true;
      if (name === 'insurance') {
        if (state.goodsValue <= 0 && action.data.value) {
          insValid = false;
        }
      }

      if (name === 'goodsValue' && action.data.value.match(/^[0-9]+$/) === null) {
        return { ...state }
      }

      // if (name === 'adr') {
      //   if (state.adrClass.length === 0 && action.data.value) {
      //     adrValid = false;
      //   }
      // }

      // if (name === 'adrClass' && action.data.value.length === 0) {
      //   adrValid = false;
      // }

      return {
        ...state,
        goodsValueError: !insValid,
        // adrClassError: !adrValid,
        loading: false,
        valid: insValid,
        results: [],
        [name]: action.data.value,


      };

    case instantPricingConstants.SHOW_ADDITIONAL_SERVICES:

      return {
        ...state,
        [action.data.name]: action.data.value,


      };

    case instantPricingConstants.CLEAR_ADDITIONAL_SERVICES:
      newState = {
        ...state,
        insurance: false,
        goodsValue: 0,
        callOnPickup: false,
        callOnDelivery: false,
        tailLift: false,
        goodsValueError: false,
        importDeclaration: false,
        exportDeclaration: false,
      }

      return {
        ...newState,
        valid: validateState(newState)
      };


    case instantPricingConstants.INITATE_ORIGIN_PLACES:
      newState = {
        ...state,
        loading: false,
        originCountry: action.country,
        originZipOptions: action.data.postalCodes,
        originZipSelected: false,
        originZipRegex: action.data.zipRegex,
        originZipExample: action.data.zipExample,
        originZip: '',
        results: [],
      }
      setUserLocalStorage(action.country.value,state.destinationCountry.value)
      return {
        ...newState,
        valid: validateState(newState)
      };


    case instantPricingConstants.UPDATE_ORIGIN_PLACES:
      newState = {
        ...state,
        loading: false,
        originCountry: action.country,
        originZipOptions: action.data.postalCodes,
        originZip: '',
        originZipSelected: false,
        originZipRegex: action.data.zipRegex,
        originZipExample: action.data.zipExample,
        selections: true,
        results: [],

      }
      setUserLocalStorage(action.country.value,state.destinationCountry.value)
      return {
        ...newState,
        valid: validateState(newState)
      };

      case instantPricingConstants.FLIP_LOCATIONS:
        newState = {
          ...state,
          loading: false,
          originCountry: state.destinationCountry,
          originZip: state.destinationZip,
          originZipOptions: state.destinationZipOptions,
          originZipSelected: state.destinationZipSelected,
          originZipRegex: state.destinationZipRegex,
          originZipExample: state.destinationZipExample,

          destinationCountry: state.originCountry,
          destinationZip: state.originZip,
          destinationZipOptions: state.originZipOptions,
          destinationZipSelected: state.originZipSelected,
          destinationZipRegex: state.originZipRegex,
          destinationZipExample: state.originZipExample,

        }
  
        return {
          ...newState,
          valid: validateState(newState)
        };

    case instantPricingConstants.INITATE_DESTINATION_PLACES:


      newState = {
        ...state,
        loading: false,
        destinationCountry: action.country,
        destinationZipOptions: action.data.postalCodes,
        destinationZip:  '',
        destinationZipSelected: false,
        destinationZipRegex: action.data.zipRegex,
        destinationZipExample: action.data.zipExample,
        results: [],
      }
      setUserLocalStorage(state.originCountry.value,action.country.value)
      return {
        ...newState,
        valid: validateState(newState)
      };

    case instantPricingConstants.UPDATE_DESTINATION_PLACES:


      newState = {
        ...state,
        loading: false,
        destinationCountry: action.country,
        destinationZipOptions: action.data.postalCodes,
        destinationZipRegex: action.data.zipRegex,
        destinationZipExample: action.data.zipExample,
        destinationZip: '',
        selections: true,
        destinationZipSelected: false,
        results: [],
      }
      setUserLocalStorage(state.originCountry.value,action.country.value)
      return {
        ...newState,
        valid: validateState(newState)
      };

    case instantPricingConstants.INITATE_OPTIONS:

      return {
        ...state,
        loading: false,
        valid: validateState(state)
      }


    case instantPricingConstants.UPDATE_ORIGIN_LOCATION:
      newState = {
        ...state,
        loading: false,
        originZip: action.location,
        originZipSelected: true,
        originZipHint: false,
        selections: true,
        results: [],
      }

      return {
        ...newState,
        valid: validateState(newState)
      };


    case instantPricingConstants.UPDATE_DESTINATION_LOCATION:

      newState = {
        ...state,
        loading: false,
        destinationZip: action.location,
        destinationZipSelected: true,
        destinationZipHint: false,
        selections: true,
        results: [],
      }

      return {
        ...newState,
        valid: validateState(newState)
      };


    case instantPricingConstants.ADD_DETAIL_ROW:

      const newRows = state.goods.concat(goodsRowModel)

      return {
        ...state,
        goods: newRows,
        valid: validateState(state)
      };

    case instantPricingConstants.REMOVE_DETAIL_ROW:

      newState = {
        ...state,
        goods: state.goods.filter((row, i) => i !== action.data.index),
      }


      return {
        ...newState,
        valid: validateState(newState)
      };

    case instantPricingConstants.UPDATE_DETAIL_ROW:
      const newName = action.data.name
      const newValue = action.data.value
      console.log(action)
      switch (action.data.type) {
        case 'goodsLine':

          const changedLine = {
            ...state.goods[action.data.index],
            [newName.concat('Error')]: !integerRegExp.test(newValue),
            [newName]: newValue
          }

          let validatedRow = validate(changedLine, false, true)

          if (newName === 'adr' && !newValue){
            const validatedRowWithEmptyClass = {
              ...validatedRow,
              adrClass: [],
              adrRow: validatedRow.adrRow.map(row => ({
                ...row,
                class: ''
              }))
            }
            validatedRow = validatedRowWithEmptyClass
          }

          const newLines = state.goods.map((loc, index) =>
            index !== action.data.index ? loc : validatedRow

          )

          newState = {
            ...state,
            goods: newLines,
            results: [],
          }

          return {
            ...newState,
            adr: newState.goods.some(goodsRow => goodsRow.adr === true),
            valid: validateState(newState)
          }
        case 'typeCode':

          let length = state.goods[action.data.index].length
          let width = state.goods[action.data.index].width
          let stack = state.goods[action.data.index].stackable
          const typeCodeText = typeOptionsRoad.find(obj => {
            return obj.value === newValue
          })

          switch (newValue) {
            case 2: //Eur
              length = 120
              width = 80
              break;
            case 3: //Fin
              length = 120
              width = 100
              break;
            case 4: //Half
              length = 60
              width = 80
              break;
            case 7: //Parcel
              stack = true;
              break;

          }

          return {
            ...state,
            results: [],
            goods: state.goods.map((loc, index) => index !== action.data.index ? loc : validate({
              ...state.goods[action.data.index],
              [newName]: newValue,
              length: length,
              width: width,
              stackable: stack,
              itemType: typeCodeText.label,
              lengthError: false,
              widthError: false,
            }))

          }
        case 'billingWeight':
          return {
            ...state,
            results: [],
            noResults: false,
            calculating: false,

            totalWeight: action.data.data.weight,
            totalLdm: action.data.data.ldm,
            totalVolume: action.data.data.volume,
            billingWeight: action.data.data.billingWeight,
            errorMessage: action.data.data.message,
            showError: !action.data.data.valid,
            showWarning: action.data.data.warning,
            groupage: action.data.data.groupage

          }
        default:
          return state
      }

    case instantPricingConstants.GET_PRICES:

      return {
        ...state,
        calculating: false,
        results: action.data.filter(x => x.totalPrice),
        noResults: action.data.filter(x => x.totalPrice).length === 0
      };

    case instantPricingConstants.SHOW_HINTS:

      var originRegex = new RegExp(state.originZipRegex)


      var destinationRegex = new RegExp(state.destinationZipRegex)



      return {
        ...state,
        originZipHint: !state.originZipSelected || (state.originZipSelected && !originRegex.test(state.originZip.value)),
        destinationZipHint: !state.destinationZipSelected || (state.destinationZipSelected && !destinationRegex.test(state.destinationZip.value)),
        goods: state.goods.map((loc, index) => validate(loc)),
      };

    case instantPricingConstants.SET_SEARCH_FROM_HISTORY:
      newState = {
        ...state,
        originCountry: {
          value: action.data.originCountry.value,
          label: action.labels.pickup
        },
        destinationCountry: {
          value: action.data.deliveryCountry.value,
          label: action.labels.delivery
        },
        originZip: action.data.originZip,
        destinationZip: action.data.deliveryZip,
        goods: action.data.goods.map((loc, index) => validate(loc)),
        selections: true,
      }

      return {
        ...newState,
        valid: validateState(newState)
      }

    case instantPricingConstants.INITATE_GOODS_ROWS:
      return {
        ...state,
        goods: [goodsRowModel],
      };

    case instantPricingConstants.UPDATE_ADR_VALUE:
      const type = action.data.name
      let adrIsValid = true;

      if (type === 'adrClass' && action.data.value.length === 0) {
        adrIsValid = false;
      }

      const updatedGoods = [...state.goods];

      updatedGoods[action.data.index] = {
        ...state.goods[action.data.index],
        adrClass: action.data.value,
        adrClassError: !adrIsValid,
        adrRow: action.data.value.map((x, index) => ({
          ...adrRowModel,
          class: action.data.value[index].value
        }))
      };

      const validatedGoods = validate(updatedGoods[action.data.index], false, true)
      const newGoods = state.goods.map((loc, index) =>
        index !== action.data.index ? loc : validatedGoods
      )

      newState = {
        ...state,
        goods: newGoods,
        loading: false,
        results: [],
      }

      return {
        ...newState,
        valid: validateState(newState),
      };

    case instantPricingConstants.UPDATE_ADR_SELECTION:

      return {
        ...state,
        adr: action.data.value
      };

    default:
      return state
  }


}
