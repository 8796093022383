import { instantPricingConstants } from '../_constants';
import { ShipmentInformationFormConstants } from '../_constants';
import { dashboardConstants } from '../_constants';
import { instantPricingService,locationService } from '../_services';
import { alertActions } from '.';
import React from 'react';
import ReactCountryFlag from "react-country-flag"
import { userService } from '_services/userService';

export const instantPricingActions = {
  initate,
  getPrice,
  changeCountry,
  changeLocation,
  addDetailRow,
  removeDetailRow,
  updateDetailRow,
  updateTotalValue,
  getBillingWeight,
  // refreshPrice,
  showHints,
  loading,
  updateValue,
  setSearchFromHistory,
  initateGoodsRows,
  showAdditionalServices,
  clearAdditionalServices,
  setInitialState,
  updateAdrValue,
  flipLocations,
  saveDefaultDirection
};

function initate(historyData, pickupCountry, deliveryCountry, selections) {
  return dispatch => {
    dispatch(request());

    // locationService.getCountriesForInstantPricing()
    locationService.getAllCountries()
      .then(
        data => {
          dispatch(success(data));
              if (historyData !== '') {
                const countryLabels = {
                  pickup: <React.Fragment><span><ReactCountryFlag svg   countryCode={pickupCountry} width="20" /></span><span style={{ marginLeft: '10px' }}>{data.find((item) => item.alpha2 === pickupCountry)?.name}</span></React.Fragment>,
                  delivery: <React.Fragment><span><ReactCountryFlag svg    countryCode={deliveryCountry} width="20" /></span><span style={{ marginLeft: '10px' }}>{data.find((item) => item.alpha2 === deliveryCountry)?.name}</span></React.Fragment>,
                }
                dispatch(instantPricingActions.setSearchFromHistory(historyData, countryLabels))
              } else if (selections){
                //ei tee mitään, jättää nykyiset valinnat
              }
              else {
  
                dispatch(instantPricingActions.changeCountry({ value: pickupCountry, label: <React.Fragment><span><ReactCountryFlag svg   countryCode={pickupCountry} width="20" /></span><span style={{ marginLeft: '10px' }}>{data.find((item) => item.alpha2 === pickupCountry)?.name}</span></React.Fragment> }, 'origin', 'initial'));
       
                dispatch(instantPricingActions.changeCountry({ value: deliveryCountry, label: <React.Fragment><span><ReactCountryFlag svg   countryCode={deliveryCountry} width="20" /></span><span style={{ marginLeft: '10px' }}>{data.find((item) => item.alpha2 === deliveryCountry)?.name}</span></React.Fragment> }, 'destination', 'initial'));
      
              }
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )

    function request() { return { type: instantPricingConstants.LOADING } }
    function success(data) { return { type: instantPricingConstants.UPDATE_COUNTRIES, data } }
    function failure(error) { return { type: instantPricingConstants.ERROR, error } }
  }
}

function setInitialState(data) { return { type: instantPricingConstants.SET_INITIAL_STATE, data } }
function flipLocations(data) { return { type: instantPricingConstants.FLIP_LOCATIONS, data } }
function getPrice(inputs){
  return dispatch => {
    dispatch(request());
    dispatch(clearModal())
    instantPricingService.getPrices(inputs)
      .then(
        data => {
          console.log(data);
          dispatch(success(data));
        },
        error => {
          dispatch(alertActions.error(error));
        }
      )

    function request() { return { type: instantPricingConstants.CALCULATE } }
    function clearModal() { return { type: ShipmentInformationFormConstants.SET_INITIAL_STATE } }
    function success(data) { return { type: instantPricingConstants.GET_PRICES, data } }

  }
}

function saveDefaultDirection(input){
  return dispatch =>{
    const model = {defaultDirection:input}
    userService.saveDefaultDirection(model)
    .then(

    )
  }
}


function loading(type='price'){

  switch(type){
    case 'price':
      return { type: ShipmentInformationFormConstants.CALCULATING_PRICE }
    break;

    case 'adr':
      return { type: ShipmentInformationFormConstants.CALCULATING_ADR }
    break;

    case 'insurance':
      return { type: ShipmentInformationFormConstants.CALCULATING_INSURANCE }
    break;


  }
   


}

function updateValue(name, value) {
  const data = { name, value }
  return { type: instantPricingConstants.UPDATE_VALUE, data };
}

function showAdditionalServices(name, value) {
  const data = { name, value }
  return { type: instantPricingConstants.SHOW_ADDITIONAL_SERVICES, data };
}

function clearAdditionalServices() {
  return { type: instantPricingConstants.CLEAR_ADDITIONAL_SERVICES };
}

function changeCountry(country, direction, type, homeZip) {

  const originActionType = type === 'initial' ? instantPricingConstants.INITATE_ORIGIN_PLACES : instantPricingConstants.UPDATE_ORIGIN_PLACES
  const destinationActionType = type === 'initial' ? instantPricingConstants.INITATE_DESTINATION_PLACES : instantPricingConstants.UPDATE_DESTINATION_PLACES
  return dispatch => {
    dispatch(request());
    dispatch(clearAdditionalServices());
    locationService.getPostalCodesByCountry(country.value)
      .then(
        data => {
          if(direction === 'origin'){
            dispatch(successFrom(data))
          }
          else{
            dispatch(successTo(data))
          }
        
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      )

    function request() { return { type: instantPricingConstants.LOADING } }
    function successFrom(data) { return { type: originActionType, data, country, homeZip } }
    function successTo(data) { return { type: destinationActionType, data, country, homeZip } }
    function failure(error) { return { type: instantPricingConstants.ERROR, error } }

  }
}

function changeLocation(location, direction) {

  return dispatch => {
    dispatch(request());

    if (direction === 'origin') {
      dispatch(changeFromLocation())
    }
    else {
      dispatch(changeToLocation())
    }

    function request() { return { type: instantPricingConstants.LOADING } }
    function changeFromLocation() { return { type: instantPricingConstants.UPDATE_ORIGIN_LOCATION, location} }
    function changeToLocation() { return { type: instantPricingConstants.UPDATE_DESTINATION_LOCATION, location} }
  }
}

function addDetailRow() {
  return { type: instantPricingConstants.ADD_DETAIL_ROW};
}

function updateTotalValue(name, value) {
  const data = { name, value}
  return { type: instantPricingConstants.UPDATE_TOTAL_VALUES, data };
}

function removeDetailRow(index) {
  const data = { index}
  return { type: instantPricingConstants.REMOVE_DETAIL_ROW, data };
}

function updateDetailRow(name, value, index) {


  return dispatch => {
    const type = name === 'typeCode' ? 'typeCode' :'goodsLine'
    const data = { name, value, type: type, index }
    dispatch(update(data));

  }

  function update(data) { return { type: instantPricingConstants.UPDATE_DETAIL_ROW, data }}
 

}

function showHints(){
 return { type: instantPricingConstants.SHOW_HINTS }
}

function getBillingWeight(rows, selectedCarrierId, calculationRule) {


  return dispatch => {

    const model = { rows: rows, carrierId: selectedCarrierId ?? 1, calculationRule: calculationRule ?? 0 }
    // dispatch(calculating());

    instantPricingService.getBillingWeight(model)
      .then(
        data => {
          console.log(data)
          const updateData = { data: data.payload, type: 'billingWeight'}
          dispatch(update(updateData));
        },
        error => {
          dispatch(failure(error));

        }
      )

  function update(data) { return { type: instantPricingConstants.UPDATE_DETAIL_ROW, data }}
  function failure(error) { return { type: instantPricingConstants.ERROR, error } }

}

}

function setSearchFromHistory(data, labels){
  return (dispatch) => {
    dispatch({ type: instantPricingConstants.SET_SEARCH_FROM_HISTORY, data, labels });
    dispatch({ type: dashboardConstants.INITATE_SEARCH_HISTORY });
  };
}

function initateGoodsRows(){
  return (dispatch) => {
    dispatch({ type: instantPricingConstants.INITATE_GOODS_ROWS });
  };
}

function updateAdrValue(name, value, index) {
  const data = { name, value, index }
  return { type: instantPricingConstants.UPDATE_ADR_VALUE, data };
}

