import React, { useEffect } from 'react';
import { ShipmentInformationFormActions, instantPricingActions } from '../../_actions';
import Grid from '@mui/material/Grid';
import { Loading } from '../Shared'
import { useDispatch, useSelector } from 'react-redux';
import SuiButton from "_softUI/components/SuiButton";
import Tooltip from '@mui/material/Tooltip';
import Preview from './Preview'
import SuiBox from '_softUI/components/SuiBox'
import CardMedia from "@mui/material/CardMedia";
import BottomBar from './BottomBar';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBadge from "_softUI/components/SuiBadge";
import ReactCountryFlag from "react-country-flag"
import { mapShipmentInformationModalToInstantPricingRequestDTO } from '../../_helpers';
import { alertActions } from '_actions';
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'
import { Paper } from '@mui/material';
import LegacyForm from './LegacyForm';
import ModernForm from './ModernForm';
import { useHistory } from 'react-router-dom';
import { isInitialState } from '_reducers/shipmentInformationForm.reducer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


export function ShipmentInformationForm({ instant, copyBooking }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(state => state.ShipmentInformationForm)
  const instantState = useSelector(state => state.instantPricing)
  const shipmentDisplayState = useSelector(state => state.shipment.selectedShipment)
  console.log(instantState)
  const windowLocation = window.location.origin;
  function handleSubmit(event) {
    event.preventDefault()
    const adrRowClasses = state.data.goods.flatMap(goodsRow => goodsRow.adrRow.map(x => x.class))

    const model = {
      ...state.data,
      adrClass: state.data.adr ? adrRowClasses.join(',') : '',
      // adrClass: state.data.adrClass ?  state.data.adrClass.map(x=> x.value).join(',') : '',
      totalPrice: state.data.totalPrice && state.data.totalPrice !== "" ? state.data.totalPrice : 0,
      shipmentType: instant ? 2 : 1, //1== SpotRequest
      clientUrl: windowLocation,
      pricingDetails: state.data.selectedQuote && state.data.selectedQuote.details.filter(function (e) {
        if (e.doubleValue > 0) {
          return e
        }
      }
      ).map(x => (
        { name: x.name, value: x.doubleValue }
      ))
    }
    dispatch(ShipmentInformationFormActions.createShipment(model));
  };

  function validateLength(label, value, maxLength, oldValue) {

    if (maxLength && value) {
      if (value.length > maxLength) {
        dispatch(alertActions.error(label + ' max length is ' + maxLength + ' characters!'))
        if(oldValue){

          return oldValue;
        } else {
          const newValue = value.substring(0, maxLength)
          return newValue;
        }


        
      }
      else {
        return value
      }
    }
    else {

      return value
    }
  }

  useEffect(() => {
    if (state.success) {
      dispatch(ShipmentInformationFormActions.clearModal())
    }
  }, [])


  useEffect(() => {
    const isInstantPage = history.location.pathname === '/InstantBookingProcess'
    const isInitial = isInitialState(state.data)
    dispatch(ShipmentInformationFormActions.updateBasicValue('shipmentType', instant ? 2 : 1))

    if (isInstantPage && isInitial && !state.loading) {
      history.push('/InstantPricing')
    }

    else if (isInstantPage && copyBooking) {
      dispatch(ShipmentInformationFormActions.getBillingWeight(state.data.goods, state.data.selectedCarrierId, state.data.calculationRule))
      calculatePrice();
    }

  }, [])

  useEffect(() => {
    if (state.success && state.ready) {
      history.push('/Ready')
    }
  }, [state.success])

  function showPreview() {
    dispatch(ShipmentInformationFormActions.showPreview(true, state.data, instant));
  }

  function hidePreview() {

    dispatch(ShipmentInformationFormActions.showPreview(false, state.data));
  }

  useEffect(() => {
    const element = !state.data.detailsValid ? "details" : !state.data.locationsValid ? "location" : "goods"
    const block = !state.data.detailsValid ? "end" : "center"
    if (!state.valid && state.previewClicked) {
      document.getElementById(element).scrollIntoView({ block: block, behavior: 'smooth' })
    }
    dispatch(ShipmentInformationFormActions.clearClickAction())
  }, [state.previewClicked])


  function getStyles(visible) {

    if (!visible) {
      return {
        padding: '1rem !important',
        paddingBottom: '0rem !important',
        marginTop: '0.5rem',
        // border: '2px solid #fff',
        borderRadius: '16px',
        "&:hover": {
          cursor: 'pointer',
          border: '1px solid #c7c8ca',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
        }
      }
    } else {
      return {
        padding: '1rem !important',

        marginTop: '0.5rem',
        borderRadius: '16px',

      }
    }

  }

  function switchPhase(current) {

    dispatch(ShipmentInformationFormActions.next(current, instant))


  }



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {

      const valid = state.data.goods.every(x => x.valid)
      if (valid) {
        dispatch(ShipmentInformationFormActions.getBillingWeight(state.data.goods, state.data.selectedCarrierId, state.data.calculationRule, (instant ? 2 : 1), state.data.calculatorId))
        calculatePrice();
      }



    }, 600)



    return () => clearTimeout(delayDebounceFn)

  }, [
    state.data.goodsCalculationTrigger,
    state.data.insurance,
    state.data.adr,
    state.data.importDeclaration,
    state.data.exportDeclaration,
    state.data.tailLift,
    state.data.goodsValue,
    state.data.callOnPickup,
    state.data.callOnDelivery,
    state.data.adrClass,
    state.data.consumerDelivery,
    state.data.heatedTransport,
  ])



  function calculatePrice() {
    // const mapOptionDTO = copyBooking ? mapShipmentInformationModalToInstantPricingRequestDTO : mapInstantPricingRequestDTO
    // const calculationState = copyBooking ? state.data : instantState

    if (instant) {
      const adrRowClasses = state.data.goods.flatMap(goodsRow => goodsRow.adrRow.map(x => x.class))
      const instantModel = {
        ...mapShipmentInformationModalToInstantPricingRequestDTO(state.data, 'refreshPrices'),
        rows: state.data.goods,
        selectedCarrierId: state.data.selectedCarrierId,
        calculatorId: state.data.calculatorId,
        additionalServices: {
          tailLift: state.data.tailLift,
          insurance: state.data.insurance,
          adr: state.data.adr,
          importDeclaration: state.data.importDeclaration,
          exportDeclaration: state.data.exportDeclaration,
          adrClass: state.data.adr ? adrRowClasses.join(',') : '',
          goodsValue: state.data.goodsValue,
          callOnPickup: state.data.callOnPickup,
          callOnDelivery: state.data.callOnDelivery,
          consumerDelivery: state.data.consumerDelivery,
          heatedTransport: state.data.heatedTransport
        }
      }

      dispatch(ShipmentInformationFormActions.refreshPrice(instantModel, state.data.calculatorId))

    }
  }



  function setVisible(name, value) {
    dispatch(ShipmentInformationFormActions.setCollapseVisibility(name, value))
  }


  const legacy = true;


  return (
    <React.Fragment >


      {state.loading &&
        <Loading text="Loading" size="h5"></Loading>
      }



      {(!state.loading && !state.success) &&
        <form onSubmit={handleSubmit}>
          <React.Fragment>



            <Grid container>


              <Grid item container xs={12}>
                {!state.preview && legacy &&
                  <>

                    <LegacyForm state={state} validateLength={validateLength} instant={instant} />





                  </>





                }
                {!state.preview && !legacy &&
                  <>


                    <ModernForm state={state} validateLength={validateLength} instant={instant} getStyles={getStyles} setVisible={setVisible} switchPhase={switchPhase} />






                  </>
                }
                {!state.preview &&
                  <BottomBar legacy={legacy} >

                    {!state.preview &&

                      <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item container xs={2} justifyContent="center">
                          {instant &&
                            <>
                              <CardMedia
                                src={state.data.selectedCarrierLogo}
                                component="img"
                                title={state.data.selectedCarrierName}

                                sx={{
                                  maxWidth: "150px",
                                  maxHeight: "50px",
                                  margin: 0,

                                  objectFit: "fill",
                                  objectPosition: "center",
                                  // borderRadius: '0.5rem',



                                }}
                              />
                              {state.data.productName &&
                                <Grid item xs={12} >
                                  <SuiTypography variant='subtitle2' sx={{ color: 'text.secondary', textAlign: 'center' }} >

                                    {state.data.productName}


                                  </SuiTypography>


                                </Grid>
                              }
                            </>
                          }
                          {!instant &&
                            <>
                              Spot
                              Requests
                            </>
                          }
                        </Grid>
                        <Grid container spacing={2} item xs={8} justifyContent="space-evenly" >

                          <Grid item sx={{ textAlign: 'left' }} xs={4} >
                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Route" />

                            <SuiTypography variant="h5" color="secondary" fontWeight="bold" textAlign="center" >
                              {state.data.locations[0].zip} {state.data.locations[0].countryAlpha2 && <ReactCountryFlag svg countryCode={state.data.locations[0].countryAlpha2} width="20" style={{ marginLeft: '0.5rem' }} />} - {state.data.locations[1].zip} {state.data.locations[1].countryAlpha2 && <ReactCountryFlag svg countryCode={state.data.locations[1].countryAlpha2} width="20" style={{ marginLeft: '0.5rem' }} />}

                            </SuiTypography>

                          </Grid>


                          <Grid item sx={{ textAlign: 'center' }} xs={4} >
                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Total billing weight" />
                            {state.calculating &&
                              <Grid container item xs={12} justifyContent="center" alignItems="center" sx={{ minHeight: '25ypx' }}>
                                <Loader size={7} color="#183B59" />

                              </Grid>
                            }

                            {!state.calculating &&
                              <SuiTypography variant="h5" color="secondary" fontWeight="bold" textAlign="center" >
                                {Math.ceil(state.data.billingWeight)} kg

                              </SuiTypography>

                            }

                          </Grid>

                          {instant &&
                            <Grid item sx={{ textAlign: 'center' }} xs={4} >
                              <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Total price" />
                              {(state.calculatingPrice || state.calculating || state.awaitingAdrClass) &&
                                <Grid container item xs={12} justifyContent="center" alignItems="center" mt={-0.7} sx={{ minHeight: '25px' }}>
                                  <Grid item xs={4}> </Grid>
                                  <Grid item xs={4}>
                                    <Loader size={7} color="#183B59" />
                                  </Grid>
                                  <Grid item xs={4} display="flex" justifyContent="flex-start" alignItems="center" mt={0.3} >
                                    {state.awaitingAdrClass &&
                                      <Tooltip title="waiting for adr class">
                                        <InfoOutlinedIcon color="primary" />
                                      </Tooltip>
                                    }
                                  </Grid>
                                </Grid>

                              }

                              {(!state.calculatingPrice && !state.calculating && !state.awaitingAdrClass) &&
                                <>
                                  <Grid container item xs={12} alignItems="center">
                                    <Grid item xs={8}>
                                      <SuiTypography variant="h5" color="secondary" fontWeight="bold" textAlign="right" >
                                        {state.data.totalPrice} {" "} {state.data.currency}

                                      </SuiTypography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <SuiBox display="flex" flexDirection="column" lineHeight={0}>
                                        <SuiTypography variant="caption" textAlign="right" fontWeight="light" color="light">
                                          excl. VAT
                                        </SuiTypography>

                                      </SuiBox>
                                    </Grid>
                                  </Grid>


                                </>
                              }


                            </Grid>
                          }
                        </Grid>

                        <Grid item xs={2} sx={{ textAlign: 'center', padding: '0.5rem' }}>
                          <SuiButton fullWidth color="primary" variant="contained" onClick={showPreview}>
                            Preview
                          </SuiButton>
                        </Grid>

                      </Grid>


                    }

                  </BottomBar>
                }
                {state.preview && <>
                  <Paper sx={{ p: 3 }}>




                    <Preview instant={instant} />


                    <Grid
                      container
                      item
                      justifyContent="space-between"
                      alignItems="flex-start"
                      xs={12}
                    >
                      <Grid item >
                        {state.preview &&
                          <SuiButton color="back" variant="contained" onClick={hidePreview}>
                            Back
                          </SuiButton>
                        }

                      </Grid>
                      <Grid item>
                        {state.preview &&
                          (<React.Fragment>
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="flex-start">
                              <Grid item >

                              </Grid>
                              <Grid item sx={{ ml: '1rem' }} >
                                <Tooltip title={!instant ? "Good to go!" : !state.data.termsAccepted ? "Please accept terms before booking!" : "Good to go!"}>
                                  <span>
                                    <SuiButton type="submit" variant="contained" disabled={!state.data.termsAccepted && instant} color="primary">
                                      {!instant ? "Send spot request" : "Confirm and book"}
                                    </SuiButton>
                                  </span>
                                </Tooltip>
                              </Grid>

                            </Grid>
                          </React.Fragment>)
                        }


                      </Grid>
                    </Grid>

                  </Paper>
                </>
                }

              </Grid>


            </Grid>


          </React.Fragment>
        </form>
      }


    </React.Fragment>
  );

}





