import { FormatColorReset } from '@mui/icons-material';
import { serviceOpsConstants } from '../../_constants';
import { goodsRowModel } from '../models';

const initialState = {
    tabValue: 0,
    fullWidthTable: false,
};

export function serviceOps(state = initialState, action) {
    switch (action.type) {
        case serviceOpsConstants.LOADING:
            switch (action.loader) {
                case 'initial':
                    return {
                        ...state,
                        loading: true

                    };
                case 'update':
                    return {
                        ...state,
                        tableUpdateLoading: true
                    };
            }

        case serviceOpsConstants.ERROR:
            return {
                ...state,
                loading: false
            };

        case serviceOpsConstants.SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.newValue
            };

        case serviceOpsConstants.TOGGLE_FULL_WIDTH_TABLE:
            return {
                ...state,
                fullWidthTable: action.isFullWidth,
            };

        default:
            return state
    }
}
