import React, { useEffect } from 'react';

import { SearchContainer, ResultsContainer } from '../_components'
import { instantPricingActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';

import { InstantPricingContainer } from "_components"
import Grid from '@mui/material/Grid';

import SuiTypography from '_softUI/components/SuiTypography';
import { AdditionalServiceContainer } from '_components/InstantPricing/AdditionalServicesContainer';

export function InstantPricing() {

  const state = useSelector(state => state.instantPricing)
  const history = useSelector(state => state.dashboard)
  const dispatch = useDispatch()


  const setValues =()=> {
    const user = JSON.parse(localStorage.getItem('user'));
    const historyData = history.searchHistorySelected ? history.selectedSearch : ''
    const pickupCountry = history.searchHistorySelected ? history.selectedSearch.originCountry.value : user.country
    const deliveryCountry = history.searchHistorySelected ? history.selectedSearch.deliveryCountry.value : 'AT'
    //dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))

    // console.log(user.defaultDirection, 'direction')

    if(history.searchHistorySelected){
      if(pickupCountry === (user?.country ?? 'FI') && deliveryCountry === (user?.country ?? 'FI')){

        localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Domestic'}) )
        dispatch(instantPricingActions.saveDefaultDirection('Domestic'))

      } else if (pickupCountry === (user?.country ?? 'FI')) {

        localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Export'}) )
        dispatch(instantPricingActions.saveDefaultDirection('Export'))

      } else if (deliveryCountry === (user?.country ?? 'FI')) {

        localStorage.setItem('user',JSON.stringify({...user,'defaultDirection':'Import'}) )
        dispatch(instantPricingActions.saveDefaultDirection('Import'))

      }
      dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))


    } else {

      switch(user.defaultDirection){
        case "Export": //export
          dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))
  
          break;
        case "Import": //import
          dispatch(instantPricingActions.initate(historyData, deliveryCountry, pickupCountry, state.selections))
  
          break;
        case "Domestic": //domestic
          dispatch(instantPricingActions.initate(historyData, user.country ?? 'FI', user.country ?? 'FI', state.selections))
  
          break;
        default:
          dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))
          break;   
      }
    }


    
  }
  
  useEffect(() => {
  
    setValues()


  }, [])

  useEffect(() => {
 
    setValues()

  }, [history.searchHistorySelected])



  return (
    <>


      <InstantPricingContainer >
        <SearchContainer />
      </InstantPricingContainer>

      <AdditionalServiceContainer /> 
      <ResultsContainer></ResultsContainer>



    </>
  )

}

