import React from 'react';
import Grid from '@mui/material/Grid';
import { MdOutlineExpand } from "react-icons/md";
import { AiOutlineColumnWidth } from "react-icons/ai";
import { CgArrowsExpandRightAlt } from "react-icons/cg";
import { GiWeight } from "react-icons/gi";
import SuiBadge from "_softUI/components/SuiBadge";
import Label from '_components/FormFields/Label'
import { MdOutlineDescription } from "react-icons/md";
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'
import Badge from '@mui/material/Badge';
import WarningIcon from '@mui/icons-material/Warning';
import { stackableOptions, fullTrailerTypes, containerTypes, typeOptionsAir, typeOptionsSea, typeOptionsInstant, typeOptionsRoad, adrPkgTypes } from '_helpers/selections'


export function GoodsPreview({ goods, state }) {

    const isFullTrailer = goods[0].typeCode === 5
    const isContainer = goods[0].typeCode === 6
    const isSpecial = isFullTrailer || isContainer
    const containerType = containerTypes.find(x => { return x.value === goods[0].containerType })
    const trailerType = fullTrailerTypes.find(x => { return x.value === goods[0].fullTrailerType })
    console.log(goods)


    return (
        <>
            <Grid item xs={12} container spacing={1}  >
                <Grid item container xs={12} spacing={1} >
                    <Grid container item xs={3} >
                        <Grid item xs={12}>

                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Items"} />
                        </Grid>
                    </Grid>
                    <Grid container item xs={5} spacing={1}  >

                        {isSpecial &&
                            <Grid item xs={7}>
                                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}><MdOutlineExpand /></span> Type</>} />
                            </Grid>
                        }

                        {!isSpecial &&
                            <>
                                <Grid item xs={3}>
                                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}>   <CgArrowsExpandRightAlt /></span> Length</>} />
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}><AiOutlineColumnWidth /></span>Width</>} />
                                </Grid>
                                <Grid item xs={3}>
                                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}><MdOutlineExpand /></span> Height</>} />
                                </Grid>


                            </>
                        }
                        <Grid item xs={isSpecial ? 5 : 3}>
                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}> <GiWeight /></span>{isSpecial ? "Total weight" : "Weight"}</>} />
                        </Grid>
                    </Grid>


                    <Grid item xs={4}>
                        <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={<><span style={{ marginRight: '0.25rem' }}><MdOutlineDescription /></span> Descriptions</>} />
                    </Grid>
                </Grid>

                {goods.map((x, index) => (




                    <Grid key={index} item container xs={12} sx={{ mb: '0.25rem', ml: '0.5rem', mt: '0.2rem', borderRadius: '6px' }} >

                        <Grid item container xs={12}>
                            <Grid item xs={3} sx={{ textAlign: 'left', paddingLeft: '1rem !important' }}>
                                <Label value={x.quantity + ' x ' + x.itemType + ' ' + (isSpecial ? "" : (x.stackable ? "Stackable" : "Not stackable"))} />
                            </Grid>
                            <Grid container item xs={5} spacing={1}  >
                                {isSpecial &&
                                    <Grid item xs={7} sx={{ textAlign: 'right', paddingRight: '0.8rem' }}>
                                        {isFullTrailer &&
                                            <Label value={"Semi-trailer (13.6ldm)"} />
                                            // <Label value={x.fullTrailerTypeName ?? trailerType.label} />
                                        }
                                        {isContainer &&
                                            <Label value={x.containerTypeName ?? containerType.label} />
                                        }
                                    </Grid>
                                }
                                {!isSpecial && <>
                                    <Grid item xs={3} sx={{ textAlign: 'right', paddingRight: '0.8rem' }}>

                                        <Label value={x.length + "cm"} />

                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: 'right', paddingRight: '0.8rem' }}>

                                        <Label value={x.width + "cm"} />

                                    </Grid>

                                    <Grid item xs={3} sx={{ textAlign: 'right', paddingRight: '0.8rem' }}>

                                        <Label value={x.height + "cm"} />

                                    </Grid>

                                </>}
                                <Grid item xs={isSpecial ? 5 : 3} sx={isSpecial ? { textAlign: 'center', paddingRight: '0rem' } : { textAlign: 'right', paddingRight: '0.8rem' }}>

                                    <Label value={x.weight + "kg"} />

                                </Grid>


                            </Grid>

                            <Grid item container xs={4} sx={{ textAlign: 'center' }}>
                                <Grid item xs={12} >
                                    <Label value={x.description} />

                                </Grid>

                            </Grid>
                        </Grid>

                        {state.adr && (
                            goods[index].adrRow.map((x, index) => (
                                <>
                                    {x.class !== '' &&
                                        <Grid item container xs={12} sx={{ pt: '0.25rem', pb: '0.25rem', mt: '0.25rem', mr: '0.5rem' }}>
                                            <Grid item xs={12} sx={{ fontStyle: 'italic', textAlign: 'left', paddingLeft: '1rem !important' }}>

                                                <Label key={index} icon={<WarningIcon sx={{ color: 'red' }} />} sx={{ color: '#494949', fontWeight: '300', fontSize: '0.75rem' }} value={

                                                    'UN' + x.unCode + ', ' +
                                                    x.class + ', ' +
                                                    x.description + ', ' +
                                                    (x.technicalDescription !== '' ? ' (' + x.technicalDescription + '), ' : '') +
                                                    (x.packingGroup !== '' ? x.packingGroup + ', ' : '') +
                                                    (x.tunnelCode !== '' ? ' (' + x.tunnelCode + ') ' : '') + ' / ' +
                                                    x.quantity + ' x ' + adrPkgTypes.find(pkgType => pkgType.value === x.packageType)?.label + ', ' +
                                                    'NW: ' + x.netWeight + ', ' +
                                                    'GW: ' + x.grossWeight + ' ' +
                                                    (x.measurementUnit === "1" ? 'KGM' : 'LTR') + ', ' +
                                                    x.netVolume + 'm³' +
                                                    (x.marinePollutant || x.limitedQuantity ? ' / ' : '') +
                                                    (x.marinePollutant ? 'MP' : '') +
                                                    (x.marinePollutant && x.limitedQuantity ? ', ' : '') +
                                                    (x.limitedQuantity ? 'LQ' : '')
                                                } />

                                            </Grid>

                                        </Grid>
                                    }
                                </>
                            )))
                        }
                    </Grid>



                ))}

                <Grid item container xs={12} spacing={1} >
                    {!isSpecial &&
                        <Grid item container xs={12}  >

                            <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="primary" badgeContent={
                                <Grid item container>
                                    <Grid container item xs={4}>
                                    </Grid>
                                    <Grid container item xs={8}>
                                        <Grid item xs={3}>
                                            TOTAL:
                                        </Grid>
                                        <Grid item xs={3}>
                                            Volume: {state.totalVolume}m3
                                        </Grid>
                                        <Grid item xs={3}>
                                            LDM: {state.totalLdm}m
                                        </Grid>
                                        <Grid item xs={3}>
                                            Billing weight:  {Math.ceil(state.billingWeight)}kg
                                        </Grid>
                                    </Grid>
                                </Grid>



                            } />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}