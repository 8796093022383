import {nullOrZeroOrEmptyOrError, nullEmptyOrError} from './helpers'


export function validateAdrRow(input, goodsWeight, adrTotalWeight) {

  let vals = []

  let newOutput = {
    ...input,
    // unCodeError: nullOrZeroOrEmptyOrError(input.class, input.unCodeError,vals),
    adrClassError: nullEmptyOrError(input.class, vals),
    unCodeError: nullEmptyOrError(input.unCode, vals),
    adrDescriptionError: nullEmptyOrError(input.description, vals),
    adrQuantityError: nullEmptyOrError(input.quantity, vals),
    adrPkgTypeError: nullEmptyOrError(input.packageType, vals),
    adrNetWeightError: nullEmptyOrError(input.netWeight, vals),
    adrGrossWeightError: nullEmptyOrError(input.grossWeight, vals),
    adrUnitError: nullEmptyOrError(input.measurementUnit, vals),
    adrNetVolumeError: nullEmptyOrError(input.netVolume, vals)
  }

  if(input.technicalNameRequired){
    newOutput = {
      ...newOutput,
      technicalNameError: nullEmptyOrError(input.technicalDescription, vals)
    }
  }

  if(adrTotalWeight > goodsWeight){
    newOutput = {
      ...newOutput,
      adrGrossWeightError: true,
      adrGrossWeightHint: true
    }
    vals.push(false)
  } else {
    newOutput = {
      ...newOutput,
      adrGrossWeightHint: false
    }
  }

  if(input.grossWeight !== '' && parseFloat(input.netWeight) > parseFloat(input.grossWeight)){
      newOutput = {
        ...newOutput,
        adrNetWeightError: true,
        adrNetWeightHint: true
      }
      vals.push(false)
    } else  {
      newOutput = {
        ...newOutput,
        adrNetWeightHint: false
      }
    }
 
  console.log(vals)
  let valid = !vals.includes(false)


  const output = {
    ...newOutput,
    valid: valid
  }

  return output
}

