
import { ConstructionOutlined, FamilyRestroom, PlayCircleFilledSharp } from '@mui/icons-material';
import { ShipmentInformationFormConstants } from '../_constants';
import { goodsRowModel, adrRowModel } from './models';
import { validate, validateLocations, validateBasicInfo, validateAdrRow } from '../_helpers'
import { nullOrZeroOrEmptyOrError } from '_helpers/Validations/helpers';

const initialState = {
  modalOpen: false,
  loading: false,
  error: false,
  calculating: false,
  calculatingPrice: false,
  calculatingAdr: false,
  calculatingInsurance: false,
  awaitingAdrClass: false,
  success: false,
  ready: false,
  preview: false,
  valid: true,
  legacyMode: false,
  collapses: {
    details: true,
    goods: false,
    locations: false,
  },

  data: {
    senderReference: '',
    receiverReference: '',
    pickupStart: '',
    deliveryStart: '',
    messageToCarrier: '',
    deadlineForQuotes: '',
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    transportationType: '',
    incoterm: '',
    incotermLocation: '',
    selectedCarrierName: '',
    selectedCarrierId: '',
    selectedCarrierLogo: '',
    calculatorId: '',
    totalPrice: '',
    weight: '',
    deliveryTime: '',
    valid: true,
    termsAccepted: false,
    file: [],
    tailLift: false,
    insurance: false,
    temperatureControl: false,
    temperature: '',
    adr: false,
    importDeclaration: false,
    exportDeclaration: false,
    customerHandlesClearances: false,
    callOnPickup: false,
    callOnDelivery: false,
    goodsValue: '',
    goodsValueError: false,
    adrClass: [],
    adrClassError: false,
    groupage: false,
    customsNeeded: false,
    customsValid: true,
    consumerDelivery: false,
    heatedTransport: false,
    goodsCalculationTrigger: 0,
    locations: [
      {
        locationType: 1,
        title: 'Pickup',
        locationOrder: 1,
        name: '',
        streetAddress: '',
        addressInfo: '',
        city: '',
        state: '',
        openingHoursFrom: '',
        openingHoursTo: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        instructions: '',
        zip: '',
        countryAlpha2: '',
        nameError: false,
        streetAddressError: false,
        cityError: false,
        contactNameError: false,
        contactEmailError: false,
        contactPhoneError: false,
        zipError: false,
        valid: true
      },
      {
        locationType: 3,
        title: 'Delivery',
        locationOrder: 2,
        name: '',
        streetAddress: '',
        addressInfo: '',
        city: '',
        state: '',
        openingHoursFrom: '',
        openingHoursTo: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        instructions: '',
        zip: '',
        countryAlpha2: '',
        nameError: false,
        streetAddressError: false,
        cityError: false,
        contactNameError: false,
        contactEmailError: false,
        contactPhoneError: false,
        zipError: false,
        valid: true

      }
    ],
    goods: [
      goodsRowModel
    ],
    totalWeight: 0,
    totalLdm: 0,
    totalVolume: 0,
    billingWeight: 0,
    detailsValid: false,
    locationsValid: false,
    goodsValid: false,
    adrRowValid: false,
    details: [],
    pricingDetails: [],
    availableAdditionalServices: {
      tailLift: false,
      insurance: false,
      adr: false,
      callOnPickup: false,
      callOnDelivery: false,
      exportDeclaration: false,
      importDeclaration: false,
      consumerDelivery: false,
    },
    adrClasses: [],
    direction: 0,
    carrierLabelDeliveryType: 0
  }
};


const integerRegExp = /^$|^\d*[0-9]\d*$/

const doubleRegExp = /^$|^([0-9]*[\.|\,])?[0-9]+/

export function ShipmentInformationForm(state = initialState, action) {
  switch (action.type) {
    case ShipmentInformationFormConstants.LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };

    case ShipmentInformationFormConstants.CALCULATING:
      return {
        ...state,
        calculating: true,

      };

    case ShipmentInformationFormConstants.CALCULATING_PRICE:
      return {
        ...state,
        calculatingPrice: true,

      };

    case ShipmentInformationFormConstants.CALCULATING_ADR:
      return {
        ...state,
        calculatingAdr: true,

      };

    case ShipmentInformationFormConstants.CALCULATING_INSURANCE:
      return {
        ...state,
        calculatingInsurance: true,

      };


    case ShipmentInformationFormConstants.SET_INITIAL_STATE:
      return {
        ...initialState
      };

    case ShipmentInformationFormConstants.SET_PRICE:

      const adrClassesInvalid = state.data.goods.some(item => item.adr && item.adrRow.some(row => row.class === ''))

      if (state.data.adr && adrClassesInvalid) {
        return {
          ...state,
          awaitingAdrClass: true
        }
      }

      if (!action.data) {
        return {
          ...state
        }
      }

      return {
        ...state,
        calculatingPrice: false,
        calculatingAdr: false,
        calculatingInsurance: false,
        awaitingAdrClass: false,
        data: {
          ...state.data,
          calculatorId: action.data.calculatorId,
          totalPrice: action.data.totalPrice,
          name: action.data.name,
          currency: action.data.currency,
          groupage: action.data.groupage,
          customsNeeded: action.data.customsNeeded,
          pricingDetails: action.data.pricingDetails,
          adrClasses: action.data.adrClasses ? action.data.adrClasses.split(',').map(item => ({ value: item, label: item })) : null,
          availableAdditionalServices: {
            adr: action.data.availableAdditionalServices.adr,
            callOnDelivery: action.data.availableAdditionalServices.callOnDelivery,
            callOnPickup: action.data.availableAdditionalServices.callOnPickup,
            exportDeclaration: action.data.availableAdditionalServices.exportDeclaration,
            importDeclaration: action.data.availableAdditionalServices.importDeclaration,
            insurance: action.data.availableAdditionalServices.insurance,
            tailLift: action.data.availableAdditionalServices.tailLift,
            consumerDelivery: action.data.availableAdditionalServices.consumerDelivery,
            heatedTransport: action.data.availableAdditionalServices.heatedTransport,
          }
        },

      };


    case ShipmentInformationFormConstants.GOODSLINE_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false
      };



    case ShipmentInformationFormConstants.OPEN:


      console.log(state)
      const preState = state.success ? initialState : state.data.shipmentType === 1 && (!action.params.prefills || action.params.prefills === undefined) ? state : initialState

      const locationOrigin = action.params.prefills && {
        ...state.data.locations[0],
        countryAlpha2: action.params.prefills.originCountry,
        zip: action.params.prefills.originZip,
        city: action.params.prefills.originCity,
        state: action.params.prefills.originState,
      }
      const locationDest = action.params.prefills && {
        ...state.data.locations[1],
        countryAlpha2: action.params.prefills.destinationCountry,
        zip: action.params.prefills.destinationZip,
        city: action.params.prefills.destinationCity,
        state: action.params.prefills.destinationState,
      }

      const locations = [locationOrigin, locationDest]

      const initatedState = action.params.prefills ? {
        ...preState,
        loading: false,

        data: {
          ...state.data,
          locations: locations,
          goods: action.params.prefills.goods,
          selectedCarrierName: action.params.prefills.carrierName,
          selectedCarrierId: action.params.prefills.carrierId,
          selectedCarrierLogo: action.params.prefills.logoUrl,
          calculatorId: action.params.prefills.calculatorId,
          productName: action.params.prefills.productName,
          totalPrice: action.params.prefills.price,
          deliveryTime: action.params.prefills.deliveryTime,
          weight: action.params.prefills.weight,
          calculationRule: action.params.prefills.calculationRule,
          tailLift: action.params.prefills.tailLift,
          insurance: action.params.prefills.insurance,
          adr: action.params.prefills.adr,
          callOnDelivery: action.params.prefills.callOnDelivery,
          callOnPickup: action.params.prefills.callOnPickup,
          currency: action.params.prefills.currency,
          goodsValue: action.params.prefills.goodsValue,
          adrClass: action.params.prefills.adrClass,
          direction: action.params.prefills.direction,
          availableAdditionalServices: action.params.prefills.availableAdditionalServices,
          importDeclaration: action.params.prefills.importDeclaration,
          exportDeclaration: action.params.prefills.exportDeclaration,


        }
      } : preState


      return {

        ...initatedState,
        legacyMode: action.params.legacyMode,
        data: {
          ...initatedState.data
        },
        loading: false,
        modalOpen: true,

      };

    case ShipmentInformationFormConstants.CLOSE:
      return {
        ...state,
        loading: false,
        modalOpen: false
      };
    case ShipmentInformationFormConstants.UPDATE_VALUE:
      const newName = action.data.name
      const newValue = action.data.value

      switch (action.data.type) {
        case 'location':
          const locToChange = state.data.locations[action.data.index]
          const changedLocation = {
            ...locToChange,
            [newName]: newValue,
            [newName.concat('Error')]: false
          }
          console.log(changedLocation)
          const newLocation = state.data.locations.map((loc, index) =>
            index !== action.data.index ? loc : changedLocation
          )
          return {
            ...state,
            data: {
              ...state.data,
              locations: newLocation
            }
          }
        case 'basic':

          if (newName === 'shipmentType' && state.data.shipmentType !== undefined) {
            if (newValue !== state.data.shipmentType) {
              return {
                ...initialState
              }
            }
          }

          if (newName === 'goodsValue' && newValue.match(/^[0-9]+$/) === null) {
            return { ...state }
          }

          return {
            ...state,
            data: {
              ...state.data,
              [newName]: newValue,
              [newName.concat('Error')]: false
            }
          }
        case 'goodsLine':
          const lineToChange = state.data.goods[action.data.index]
          const changedLine = {
            ...lineToChange,
            [newName.concat('Error')]: newName === 'description' ? false : !integerRegExp.test(newValue),
            [newName]: newValue
          }

          let validatedRow = validate(changedLine)
          const awaitingAdrClass = newName === 'adr' ? newValue : state.awaitingAdrClass

          if (newName === 'adr')
          {
            if(!newValue)
            {
              validatedRow = {
                ...validatedRow,
                adrRow: []
              }
            } 
            else 
            {
              validatedRow = {
                ...validatedRow,
                adrRow: [adrRowModel]
              }
            }
          }

          const newLines = state.data.goods.map((loc, index) =>
            index !== action.data.index ? loc : validatedRow
          )

          return {
            ...state,
            awaitingAdrClass: awaitingAdrClass,
            data: {
              ...state.data,
              goods: newLines,
              adr: newLines.some(goodsRow => goodsRow.adr === true),
              goodsCalculationTrigger: state.data.goodsCalculationTrigger + 1
            }
          }

        case 'typeCode':

          let length = state.data.goods[action.data.index].length
          let width = state.data.goods[action.data.index].width
          let stack = state.data.goods[action.data.index].stackable
          let itemType = "Parcel"
          switch (newValue) {
            case 1: //Colli

              itemType = "Colli"
              break;

            case 2: //Eur
              length = 120
              width = 80
              itemType = "Eur"
              break;
            case 3: //Fin
              length = 120
              width = 100
              itemType = "Fin"
              break;
            case 4: //Half
              length = 60
              width = 80
              itemType = "Half"
              break;
            case 5:
              itemType = "Full Trailer"
              break;
            case 6:
              itemType = "Container"
              break;
            case 7: //Parcel
              stack = true;
              break;

          }

          return {
            ...state,
            data: {
              ...state.data,
              goods: state.data.goods.map((loc, index) => index !== action.data.index ? loc : validate({
                ...state.data.goods[action.data.index],
                [newName]: newValue,
                itemType: itemType,
                length: length,
                width: width,
                stackable: stack,
                lengthError: false,
                widthError: false,
              }))
            }
          }
        case 'billingWeight':

          return {
            ...state,
            calculating: false,
            data: {
              ...state.data,
              totalWeight: action.data.data.weight,
              totalLdm: action.data.data.ldm,
              totalVolume: action.data.data.volume,
              billingWeight: action.data.data.billingWeight,
              errorMessage: action.data.data.message,
              showError: !action.data.data.valid,
              showWarning: action.data.data.warning
            }
          }



        default:
          return state

      };

    case ShipmentInformationFormConstants.ADD_GOODSLINE:

      return {
        ...state,
        data: {
          ...state.data,
          goods: state.data.goods.concat(goodsRowModel)
        }
      };

    case ShipmentInformationFormConstants.REMOVE_GOODSLINE:

      return {
        ...state,
        data: {
          ...state.data,
          goods: state.data.goods.filter((row, i) => i !== action.data.index),
          goodsCalculationTrigger: state.data.goodsCalculationTrigger + 1
        }
      };

    case ShipmentInformationFormConstants.RESET_GOODS:

      return {
        ...state,
        data: {
          ...state.data,
          goods: initialState.data.goods
        }
      };



    case ShipmentInformationFormConstants.SET_SAVED_LOCATION:


      const newLoc = {
        ...state.data.locations[action.data.index],
        name: action.data.location.name,
        streetAddress: action.data.location.streetAddress,
        addressInfo: action.data.location.addressInfo,
        city: action.data.location.city,
        openingHours: action.data.location.openingHours,
        openingHoursFrom: action.data.location.openingHoursFrom,
        openingHoursTo: action.data.location.openingHoursTo,
        contactName: action.data.location.contactName,
        contactEmail: action.data.location.contactEmail,
        contactPhone: action.data.location.contactPhone.replace(/[^\d+]/g, ''),
        messageToContact: action.data.location.messageToContact,
        zip: action.data.location.zip,
        countryAlpha2: action.data.location.countryAlpha2,
        // ...action.data.location

      }
      console.log(newLoc)

      const newLocs = state.data.locations.map((ln, i) => i !== action.data.index ? ln : newLoc)

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          locations: newLocs,

        }

      };
    case ShipmentInformationFormConstants.SUCCESS:
      return {
        ...state,
        loading: false,
        id: action.data.id,
        shipmentType: action.data.shipmentType,
        data: {
          ...state.data,
          file: action.data.payload.file
        },
        success: true,
        ready: true
      };


    case ShipmentInformationFormConstants.STORE_FILE:


      return {
        ...state,
        data: {
          ...state.data,
          file: state.data.file.concat(action.file)
        }
      };

    case ShipmentInformationFormConstants.DELETE_FILE:


      return {
        ...state,
        data: {
          ...state.data,
          file: state.data.file.filter((row, i) => i !== action.index)
        }
      };
    case ShipmentInformationFormConstants.CLEAR_LOCATION:

      const clearedLoc = {
        ...state.data.locations[action.data.index],
        name: '',
        streetAddress: '',
        addressInfo: '',
        city: '',
        openingHours: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        messageToContact: '',
        nameError: false,
        streetAddressError: false,
        cityError: false,
        contactNameError: false,
        contactEmailError: false,
        contactPhoneError: false,
        zip: action.data.instant ? state.data.locations[action.data.index].zip : '',
        zipError: false,
        countryAlpha2: action.data.instant ? state.data.locations[action.data.index].countryAlpha2 : '',
        countryAlpha2Error: false,
        valid: true
      }

      const newLocationsCleared = state.data.locations.map((ln, i) => i !== action.data.index ? ln : clearedLoc)

      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...state.data,
          locations: newLocationsCleared

        }
      };

    case ShipmentInformationFormConstants.ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };

    case ShipmentInformationFormConstants.HANDLE_ACCEPT_TERMS:
      return {
        ...state,
        data: {
          ...state.data,
          termsAccepted: action.value
        }
      };

    case ShipmentInformationFormConstants.SHOW_PREVIEW:

      if (action.params.preview) {
        let validatedLocations = null
        let validatedGoodRows = null
        let validateddata = null
        let validationResultsLocations = []
        let validationResultsGoods = []
        let validationResultsAdr = []
        let validatedAdrRows = null
        let adrRowIsValid = true


        validatedLocations = state.data.locations.map(x => validateLocations(x))
        validatedLocations.map(x => validationResultsLocations.push(x.valid))


        validateddata = validateBasicInfo(state.data, !action.params.instant)

        let customsValid = !state.data.customsNeeded
        // let adrValid = (state.data.adr && state.data.adrClass.length > 0) || !state.data.adr



        let insuranceValid = (state.data.insurance && state.data.goodsValue > 0) || !state.data.insurance

        if (state.data.customsNeeded) {
          customsValid = state.data.customerHandlesClearances || state.data.importDeclaration || state.data.exportDeclaration

        }



        validatedGoodRows = state.data.goods.map(x => validate(x, true))
        validatedGoodRows.map(x => validationResultsGoods.push(x.valid))

        // if (state.data.adr) {
        //   validatedAdrRows = state.data.goods
        //     .filter(row => row.adr)
        //     .flatMap(row => row.adrRow)
        //     .map(adrRow => validateAdrRow(adrRow, state.data.totalWeight));

        //   adrRowIsValid = validatedAdrRows.every(adrRow => adrRow.valid);
        // }

        if (state.data.adr) {
          validatedAdrRows = state.data.goods
          .filter(row => row.adr)
          .flatMap(row => {
            const totalGrossWeight = row.adrRow.reduce((sum, adrRow) => {
              return sum + (parseFloat(adrRow.grossWeight) || 0);
            }, 0);
      
            return row.adrRow.map(adrRow => validateAdrRow(adrRow, row.weight, totalGrossWeight));
          });

          adrRowIsValid = validatedAdrRows.every(adrRow => adrRow.valid);
        }

        const goodsValid = !validationResultsGoods.includes(false)
        const locationsValid = !validationResultsLocations.includes(false)


        const validForPreview = goodsValid && locationsValid && adrRowIsValid && validateddata.valid && !state.data.showError && customsValid && insuranceValid

        return {
          ...state,
          preview: action.params.preview ? validForPreview : false,
          valid: validForPreview,
          processIndex: 2,
          data: {
            ...validateddata,
            locations: validatedLocations,
            adrRowValid: adrRowIsValid,
            goodsValueError: !insuranceValid,
            goods: validatedGoodRows,
            totalPrice: action.params.totalPrice,
            selectedQuote: action.params.selectedQuote,
            detailsValid: validateddata.valid,
            goodsValid: goodsValid,
            locationsValid: locationsValid,
            customsValid: customsValid
          },
          collapses: {
            ...state.data.collapses,
            'details': !validateddata.valid,
            'locations': validatedLocations.map(x => x.valid).includes(false),
            'goods': validatedGoodRows.map(x => x.valid).includes(false),
          },
          previewClicked: true

        };
      }
      else {
        return {
          ...state,
          preview: false,
          previewClicked: true,
        }
      }

    case ShipmentInformationFormConstants.VALIDATE_DETAILS:

      let validateddata = null
      validateddata = validateBasicInfo(state.data, !action.instant)

      return {
        ...state,
        valid: state.valid && validateddata.valid,

        data: {
          ...validateddata,
          detailsValid: validateddata.valid,
        },
        collapses: {
          ...state.collapses,
          'details': !validateddata.valid,
          'locations': state.collapses.locations ? state.collapses.locations : (validateddata.valid && !state.data.locationsValid)
        }

      };

    case ShipmentInformationFormConstants.VALIDATE_GOODS:

      let validatedGoodRows = null
      let validationResultsGoods = []
      validatedGoodRows = state.data.goods.map(x => validate(x, true))
      validatedGoodRows.map(x => validationResultsGoods.push(x.valid))
      const goodsValid = !validationResultsGoods.includes(false)
      return {
        ...state,
        valid: state.valid && goodsValid,

        data: {
          ...state.data,
          goods: validatedGoodRows,
          goodsValid: goodsValid,
        },
        collapses: {
          ...state.collapses,
          'goods': !goodsValid,

        }

      };

    case ShipmentInformationFormConstants.VALIDATE_LOCATIONS:

      let validatedLocations = null
      let validationResultsLocations = []

      validatedLocations = state.data.locations.map(x => validateLocations(x))
      validatedLocations.map(x => validationResultsLocations.push(x.valid))
      const locationsValid = !validationResultsLocations.includes(false)
      return {
        ...state,
        valid: state.valid && locationsValid,

        data: {
          ...state.data,
          locationsValid: locationsValid,
          locations: validatedLocations,
        },
        collapses: {
          ...state.collapses,
          'locations': !locationsValid,
          'goods': state.collapses.goods ? state.collapses.goods : (locationsValid && !state.data.goodsValid)

        }

      };

    case ShipmentInformationFormConstants.SET_COLLAPSE_VISIBILITY:


      return {
        ...state,
        collapses: {
          ...state.collapses,
          [action.params.name]: action.params.value
        }


      };

    case ShipmentInformationFormConstants.UPDATE_SHIPMENT_TYPE_IN_STORE:
      return {
        ...state,
        direction: action.data.direction,
        carrierLabelDeliveryType: action.data.carrierLabelDeliveryType
      };

    case ShipmentInformationFormConstants.SET_READY:
      return {
        ...state,
        ready: false,
      };

    case ShipmentInformationFormConstants.CLEAR_CLICK_ACTION:
      return {
        ...state,
        previewClicked: false,
      };

    case ShipmentInformationFormConstants.COPY:

      const adrClassArray = action.data.adrClass ? action.data.adrClass.split(',').map(item => ({ value: item, label: item })) : [];

      const newObj = deepMerge(initialState.data, action.data);
      const validGoods = newObj.goods.map(element => {
        return {...element,valid:true}
      });
      const newData = {
        ...initialState.data,
        ...newObj,
        goods:validGoods,
        pickupStart: null,
        calculatorId: action.data.calculatorId,
        file: [],
        adrClass: adrClassArray,
        deadlineForQuotes: '',
      };


      return {
        ...initialState,
        data: newData,
        modalOpen: true
      };

    case ShipmentInformationFormConstants.UPDATE_UNCODE_OPTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          goods: state.data.goods.map((goods, goodsIndex) => {
            if (goodsIndex === action.goodsIndex) {
              return {
                ...goods,
                adrRow: goods.adrRow.map((adrRow, adrIndex) => {
                  if (adrIndex === action.adrIndex) {
                    return {
                      ...adrRow,
                      unCodeOptions: action.data
                    };
                  }
                  return adrRow;
                })
              };
            }
            return goods;
          })
        }
      };

    case ShipmentInformationFormConstants.UPDATE_ADRDESCRIPTION_OPTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          goods: state.data.goods.map((goods, goodsIndex) => {
            if (goodsIndex === action.goodsIndex) {
              return {
                ...goods,
                adrRow: goods.adrRow.map((adrRow, adrIndex) => {
                  if (adrIndex === action.adrIndex) {
                    return {
                      ...adrRow,
                      adrDescriptionOptions: action.data
                    };
                  }
                  return adrRow;
                })
              };
            }
            return goods;
          })
        }
      };

    case ShipmentInformationFormConstants.UPDATE_ADR_ROW:
      const { index, name, value, adrIndex } = action.data;


      const newGoods = state.data.goods.map((goodsRow, i) => {
        if (i === index) {
          const newGoodsRow = {
            ...goodsRow,
            //adrClass: name === 'class' ? goodsRow.adrClass.concat(value) : goodsRow.adrClass
          };
          
          const totalGrossWeight = newGoodsRow.adrRow.reduce((sum, row, currentIndex) => {
            const grossWeight = currentIndex === adrIndex && name === 'grossWeight' ? parseFloat(value) : parseFloat(row.grossWeight);
            return sum + (grossWeight || 0);
          }, 0);

          const newAdrRow = { ...newGoodsRow.adrRow[adrIndex] };
          newAdrRow[name] = value;
          if (name === 'description') {
            newAdrRow.subsidiaryRisk = action.data.subsidiaryRisk;
            newAdrRow.technicalNameRequired = action.data.technicalNameRequired;
            newAdrRow.tunnelCode = action.data.tunnelCode;
            newAdrRow.packingGroup = action.data.packingGroup;
          }
          // const validatedAdrRow = validateAdrRow(newAdrRow, state.data.totalWeight);
          const validatedAdrRow = validateAdrRow(newAdrRow, newGoodsRow.weight, totalGrossWeight);
          newGoodsRow.adrRow[adrIndex] = validatedAdrRow;
          return newGoodsRow;
        }
        return goodsRow;
      });

      const validatedGoods = newGoods.map(goodsRow => {
        return {
          ...goodsRow,
          valid: validate(goodsRow)
        }
      })

      const awaitingAdrClass = validatedGoods.some(item => item.adrRow.some(row => row.class === ''))

      return {
        ...state,
        awaitingAdrClass: awaitingAdrClass,
        data: {
          ...state.data,
          goods: validatedGoods,
          goodsCalculationTrigger: name === 'class' || name === 'limitedQuantity' ? state.data.goodsCalculationTrigger + 1 : state.data.goodsCalculationTrigger
        },
      };


    case ShipmentInformationFormConstants.ADD_ADRLINE:
      const { goodsIndex } = action
      return {
        ...state,
        data: {
          ...state.data,
          goods: state.data.goods.map((item, index) => {
            if (index === goodsIndex) {
              return {
                ...item,
                adrRow: [...item.adrRow, adrRowModel] // Add adrRowModel to existing adrRow array
              };
            }
            return item;
          })
        }
      };

    case ShipmentInformationFormConstants.REMOVE_ADRLINE:

      const updatedGoods = state.data.goods.map((goodsItem, index) => {
        if (index === action.data.goodsIndex) {
          const updatedAdrRow = goodsItem.adrRow.filter((row, i) => i !== action.data.adrIndex);
          return { ...goodsItem, adrRow: updatedAdrRow };
        }
        return goodsItem;
      });

      return {
        ...state,
        data: {
          ...state.data,
          goods: updatedGoods
        }
      };


    default:
      return state
  }
}






function deepMerge(initialState, action) {
  if (typeof initialState !== 'object' || typeof action !== 'object') {
    // If either initialState or action is not an object, return action
    return action;
  }

  if (Array.isArray(initialState) && Array.isArray(action)) {
    // If both initialState and action are arrays, merge them
    const maxLength = Math.max(initialState.length, action.length);
    const mergedArray = [];

    for (let i = 0; i < maxLength; i++) {
      mergedArray[i] = deepMerge(initialState[i], action[i]);
    }

    return mergedArray;
  }
  const newObj = {};

  for (const key in initialState) {
    if (key === 'goods' && Array.isArray(initialState[key]) && Array.isArray(action[key])) {
      // If key is 'goods' and both initialState and action have goods array
      const maxLength = Math.max(initialState[key].length, action[key].length);
      newObj[key] = [];

      for (let i = 0; i < maxLength; i++) {
        if (action[key][i].adrRow.length === 0) {
          action[key][i].adrRow = goodsRowModel.adrRow
        }

        //  if (!newObj[key][i].adrRow) {
        //     newObj[key][i].adrRow = goodsRowModel.adrRow
        //   }
        // Merge each goodsRow
        newObj[key][i] = deepMerge(initialState[key][i], action[key][i]);

      }
    }
    if (action !== null && key in action) {
      newObj[key] = deepMerge(initialState[key], action[key]);
    } else {
      newObj[key] = initialState[key];
    }

  }

  return newObj;
}

export function isInitialState(state) {
  console.log('initial', initialState.data)
  console.log('current', state)
  return initialState.data === state
}