import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Link, NavLink } from "react-router-dom";
import AdminBookings from './AdminBookingsTab';
import AdminSpotRequests from './AdminSpotRequestsTab';
import FollowUpTab from './AdminFollowUpTab';
import InvoicingTab from './AdminInvoicingTab';
import TasksTab from './AdminTasksTab';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { serviceOpsActions } from '../../_actions';


export default function ServiceOps() {
    // const [tabValue, setTabValue] = React.useState(3);
    const dispatch = useDispatch();
    const state = useSelector(state => state.serviceOps)
    const tabValue = state.tabValue
    const fullWidthTable = state.fullWidthTable

    useEffect(() => {
        const storedValue = localStorage.getItem('tabValue');
        if (storedValue !== null) {
              dispatch(serviceOpsActions.setTabValue(parseInt(storedValue, 10))); 
        }
      }, [dispatch]);

    const handleChange = (event, newValue) => {
        dispatch(serviceOpsActions.setTabValue(newValue));
        localStorage.setItem('tabValue', newValue); 
    };

    const gridStyles = fullWidthTable ? {
        height: '100%',
        position: 'absolute',
        top: 'auto',
        width: '98vw',
        left: 'calc((98vw - 100%) / -2)',
    } : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        // <Grid
        // container
        // display='flex'
        // justifyContent='center'
        // alignItems='center'>
        <Grid
        container
        sx={gridStyles} >

                <Grid container item xs={12} sx={{ height: '100%' }} mb={fullWidthTable ? 1 : 0}>
                    <Paper pt={0} sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <Tabs value={tabValue} onChange={handleChange} centered>
                                <Tab label="Insta bookings"/>
                                <Tab label="Spot requests"/>
                                <Tab label="Shipment followup"/>
                                <Tab label="Admin tasks"/>
                                <Tab label="Invoicing"/>
                            </Tabs>
                            
                        </Box>  
                    </Paper>
                </Grid>
                {tabValue == 0 &&
                <AdminBookings/>
                }
                {tabValue == 1 &&
                <AdminSpotRequests/>
                }
                {tabValue == 2 &&
                <FollowUpTab/>
                }
                {tabValue == 3 &&
                <TasksTab/>
                }
                {tabValue == 4 &&
                <InvoicingTab/>
                }
            </Grid>
    )

}

